@import "../../../styles/var";

.five {
}
.fiveBody {
}
.container {
    @media (max-width: $md3+px) {
        padding-right: 0 !important;
    }
}
.fiveTitle {
    margin-bottom: 40px;
    @media (max-width: $md5+px) {
        padding-right: 40px;
    }
    @media (max-width: 600px) {
        padding-right: 18px;
    }
}
.fiveSubTitle {
    margin-bottom: 60px;
    max-width: 800px;
    @media (max-width: $md1+px) {
        max-width: 600px;
    }
    @media (max-width: $md5+px) {
        padding-right: 40px;
    }
    @media (max-width: 600px) {
        padding-right: 18px;
    }
}
.fiveWrap {
    @media (max-width: $md3+px) {
        overflow: auto;
    }
}
.fiveTable {
    @media (max-width: $md3+px) {
        width: 1200px;
    }
    @media(max-width: $md6+px) {
        width: 1000px;
    }
}
.fiveTableTop {
    display: flex;
    align-items: center;
}
.fiveTableTopItem {
    flex: 0 1 337px;
    padding: 10px 40px 10px 40px;
    font-weight: 800 !important;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #335F62 !important;
    height: 70px;
    @media (max-width: $md2+px) {
        padding: 12px 20px 12px 20px;
        height: 68px;
        font-size: 18px;
    }
    img {
        margin-right: 12px;
        width: 32px;
        height: 32px;
        @media(max-width: $md6+px) {
            width: 26px;
            height: 26px;
        }
    }
    &:nth-child(1) {
        color: rgba(0, 0, 0, 0.7) !important;
        font-weight: 300 !important;
        padding: 22px 20px 22px 0px !important;
        justify-content: flex-start;
    }
    &:nth-child(2) {
        background-color: #E3EFF2;
    }
    &:nth-child(3) {
        background-color: #E3EFF2;
    }
    &:nth-child(4) {
        background-color: #E3EFF2;
    }
    &:nth-child(5) {
        background-color: #E3EFF2;
    }
}
.fiveTableRow {
    display: flex;
}
.fiveTableRowItem {
    text-align: center;
    flex: 0 1 337px;
    padding: 10px 40px 10px 40px;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.7) !important;
    justify-content: center;
    height: 70px;
    @media (max-width: $md2+px) {
        padding: 5px 20px 5px 20px;
    }
    @media(max-width: $md6+px) {
        height: 60px;
    }
    img {
        margin-right: 10px;
    }
    &:nth-child(1) {
        text-align: left;
        color: rgba(0, 0, 0, 0.7) !important;
        font-weight: 300 !important;
        padding: 5px 20px 5px 0px !important;
        justify-content: flex-start;
    }
    &:nth-child(2) {
        background-color: #E3EFF2;
    }
    &:nth-child(3) {
        background-color: transparent;
    }
    &:nth-child(4) {
        background-color: #E3EFF2;
    }
    &:nth-child(5) {
        background-color: transparent;
    }
}
