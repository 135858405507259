@import '../../../styles/var';

.tradeAssets {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        width: 552px;
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .title {

        }
        .text {
            margin: 40px 0 60px 0;
            max-width: 700px;
            @media(max-width: $md4+px) {
                margin: 20px 0 40px 0;
            }
        }
        .cols {
            display: grid;
            grid-template-columns: repeat(3, auto);
            justify-content: space-between;
            gap: 40px;
            @media(max-width: 600px) {
                grid-template-columns: repeat(2, auto);
                justify-content: unset;
            }
            .col {
                &Text {

                }
                ul {
                    margin-top: 16px;
                    @media(max-width: $md6+px) {
                        margin-top: 12px;
                    }
                }
            }
        }
        .buttons {
            display: flex;
            gap: 20px;
            margin-top: 60px;
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
            .btn {

            }
        }
    }
    .imageWrap {
        position: absolute;
        overflow: hidden;
        right: 0;
        top: 0;
        width: 552px;
        border-radius: 100%;
        @media(max-width: $md2+px) {
            width: 450px;
            top: calc(50% - 450px / 2);
        }
        @media(max-width: $md3+px) {
            width: 300px;
            top: calc(50% - 150px);
        }
        @media(max-width: $md4+px) {
            position: relative;
            top: unset;
            right: unset;
            display: flex;
            margin: 60px auto 0 auto;
            width: 445px;
        }
        @media(max-width: $md6+px) {
            width: 335px;
        }
        .image {
            position: relative;
            width: 100%;
        }
    }
}