@import '../../../styles/var';

.priorities {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        .title {
            max-width: 511px;
        }
        .text {
            max-width: 552px;
            margin: 40px 0;
            @media(max-width: $md4+px) {
                max-width: 700px;
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        width: 601px;
        @media(max-width: $md2+px) {
            width: 550px;
        }
        @media(max-width: $md3+px) {
            width: 450px;
            right: -50px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 60px auto 0 auto;
            right: unset;
            bottom: unset;
            width: 100%;
            max-width: 601px;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}