@import "../../../styles/var";

.block {
}
.blockBody {
}
.blockRow {
    display: flex;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
    @media (max-width: $md3+px) {
        display: none;
    }
}
.blockRowLeft {
    flex: 1 1 auto;
}
.blockRowLeftContent {
    padding: 40px 60px 40px 60px;
}
.blockRowLeftContentIcon {
    margin-bottom: 20px;
}
.blockRowLeftContentTitle {
    margin-bottom: 15px;
}
.blockRowLeftContentText {
    max-width: 566px;
}
.blockRowRight {
    flex: 0 0 450px;
    border-left: 1px solid #e9e9e9;
}
.blockRowRightTab {
    border-bottom: 1px solid #e9e9e9;
    padding: 40px;
    display: flex;
    justify-content: space-between;

    &:last-child {
        margin-bottom: unset;
    }
}

.blockFaqMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
    }
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
}

.blockFaqMobColumnIcon {
    display: none;
    margin-bottom: 20px;
}
.blockFaqMobColumnTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}
.blockFaqMobColumnText {
    display: none;
    margin-top: 15px;
    @media (max-width: $md6+px) {
        margin-top: 0;
    }
}
.blockFaqMobColumn {
    border-bottom: 1px solid #e9e9e9;
    padding: 0 40px;
    @media (max-width: $md6+px) {
        padding: 0 20px;
    }
    &:last-child {
        border-bottom: unset;
    }
    &.active {
        padding-top: 20px;
        padding-bottom: 40px;
        .blockFaqMobColumnIcon,
        .blockFaqMobColumnText {
            display: block;
        }
        .blockFaqMobColumnIcon,
        .blockFaqMobColumnTitle img {
            display: none !important;
        }
    }
}
