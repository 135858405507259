@import "../../../styles/var";

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 25px);
    }
}
@include keyframes(top-anim3) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}
@include keyframes(top-anim4) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}

.bannerRowRight {
    flex: 0 1 552px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    display: flex;
    @media (max-width: $md3+px) {
        justify-content: center;
    }
    @media (max-width: $md6+px) {
        flex-wrap: wrap;
    }
}
.bannerRowRightBtns {
    @media (max-width: $md6+px) {
        flex: 0 1 100%;
        display: flex;
        order: 1;
    }
}
.bannerRowRightBtnsBtn {
    width: 66px;
    height: 66px;
    img {
        width: 32px;
    }
    @media (max-width: $md6+px) {
        width: 48px;
        height: 48px;
        img {
            width: 20px;
            height: 20px;
        }
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all 0.3s linear;
    border: 1px solid #e3eff2;
    &:first-child {
        border-radius: 0px 10px 0px 0px;
        @media (max-width: $md6+px) {
            border-radius: 10px 0px 0px 0px;
        }
    }
    &:last-child {
        border-radius: 0px 0px 10px 0px;
        @media (max-width: $md6+px) {
            border-radius: 0px 10px 0px 0px;
        }
    }
    &.active {
        background-color: #e3eff2;
    }
}
.spbw {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.bannerRowRightContent {
    padding: 40px;
    background: #e3eff2;
    border-radius: 10px 0px 10px 10px;
    min-height: 400px;
    @media (max-width: $md6+px) {
        order: 2;
        border-radius: 0px 10px 10px 10px;
        min-height: unset;
    }
    flex: 0 1 486px;
    @media (max-width: $md2+px) {
        padding: 20px;
        flex: 0 1 450px;
    }
}
.bannerRowRightContentTab {
    display: none;
    height: 100%; 
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &.active {
        display: flex;
    }
}
.bannerRowRightContentTabTitle {
    margin-bottom: 20px;
}
.bannerRowRightContentTabGreyTexts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.bannerRowRightContentTabGreyTextsLeft {
    color: #b3c7ca !important;
    flex: 0 1 180px;
}
.bannerRowRightContentTabGreyTextsRight {
    flex: 0 1 100px;
    color: #b3c7ca !important;
}
.bannerRowRightContentTabBtn {
    margin-top: 30px;
}

.account {
}
.accountBody {
}
.accountRow {
    display: flex;

    justify-content: space-between;
    @media (max-width: $md3+px) {
        justify-content: center;
    }
}
.accountRowLeft {
}
.accountRowLeftTitle {
    margin-bottom: 20px;
    @media (max-width: $md3+px) {
        text-align: center;
    }
}
.accountRowLeftText {
    margin-bottom: 60px;
    max-width: 396px;
    @media (max-width: $md3+px) {
        margin: 0 auto 60px auto;
        text-align: center;
    }
}
.accountRowRight {
}

.accountRowRight {
    position: relative;

    @media (max-width: $md3+px) {
        display: none;
    }
}
.accountRowRightImgs {
    width: 656px;
    height: 568px;
    position: absolute;
    right: 0;
    bottom: 0;
    @media (max-width: $md2+px) {
        transform: scale(0.8);
        right: -50px;
        top: 100px;
    }
}
.accountRowRightImgsImg {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    width: 656px;
    height: 568px;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.accountRowRightImgsImg1 {
    position: absolute;
    left: 10px;
    top: 160px;
    opacity: 1;
    width: 30px;
    height: 118px;
    img {
        @include animation("top-anim1 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.accountRowRightImgsImg2 {
    position: absolute;
    left: 100px;
    top: 60px;
    opacity: 1;
    width: 31px;
    height: 88px;
    img {
        @include animation("top-anim2 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.accountRowRightImgsImg3 {
    position: absolute;
    left: 430px;
    top: 120px;
    opacity: 1;
    width: 30px;
    height: 97px;
    img {
        @include animation("top-anim3 linear 1.4s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.accountRowRightImgsImg4 {
    position: absolute;
    left: 600px;
    top: 210px;
    opacity: 1;
    width: 26px;
    height: 96px;
    img {
        @include animation("top-anim4 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.accountRowRightImgsImg5 {
    position: absolute;
    left: 550px;
    top: 310px;
    opacity: 1;
    width: 27px;
    height: 151px;
    img {
        @include animation("top-anim2 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
