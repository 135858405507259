@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s;

    padding: 13px 30px;
    border-radius: 40px;
    border: 1px solid #669295;
    background: #669295;

    font-family: $roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: 16px;
    }
    &:hover {
        background: transparent;
        color: #669295;
    }
    span {
        position: relative;
        width: 100%;
        text-align: center;
    }
    &.secondary {
        background: transparent;
        color: #669295;
        &:hover {
            background: #669295;
            color: #fff;
        }
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
