@import "../../../styles/var";

.footerWrap {
    padding-bottom: 15px;
    padding-top: 35px;
    background: #fff;
}

.footer {
    position: relative;
    z-index: 10;
    color: #4d4d4d;
    display: grid;
    grid-template-columns: 150px auto 1fr;
    gap: 60px;
    @media (max-width: $md1+px) {
        grid-template-columns: 150px auto 1fr;
    }
    @media (max-width: $md2+px) {
        grid-template-columns: 150px auto 1fr;
    }
    @media (max-width: $md3+px) {
        grid-template-columns: 140px 1fr;
        gap: 30px 100px;
        .logo {
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .info {
            grid-row-start: 1;
            grid-row-end: 1;
        }
        .disclaimer {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }
    @media (max-width: $md5+px) {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    a {
        color: #4d4d4d;
        text-decoration: none;
        &:hover {
            color: #000;
        }
    }
    .logo {
        width: 100%;
        img {
            width: 150px;
            @media(max-width: $md3+px) {
                width: 140px;
            }
            @media(max-width: $md6+px) {
                width: 120px;
            }
        }
    }
    .info {
        font-family: $roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #4d4d4d;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media (max-width: $md3+px) {
            justify-content: unset;
            flex-direction: unset;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }
        .contacts,
        .docs {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }
    .disclaimer {
        font-family: $roboto;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        &Title {
            font-weight: 400;
            margin-bottom: 6px;
        }
        .rights {
            text-align: right;
            font-family: $roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            margin-top: 20px;
            @media (max-width: $md1+px) {
                margin-top: 30px;
            }
            @media (max-width: $md4+px) {
                margin-top: 20px;
                text-align: left;
            }
        }
        .num {
            margin-top: 15px;
            font-size: 16px;
            font-weight: 400;
        }
    }
}
