@import '../../../styles/var';

.whyPlatform {
    position: relative;
    .cards {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        margin-top: 40px;
        @media(max-width: $md4+px) {
            display: none;
        }
    }
    .card {
        position: relative;
        padding: 40px 40px 48px 40px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
        width: 100%;
        height: 100%;
        @media(max-width: $md4+px) {
            padding: 40px;
        }
        @media(max-width: $md6+px) {
            padding: 20px 20px 30px 20px;
        }
        &Icon {
            width: 40px;
        }
        &Title {
            margin: 16px 0;
            @media(max-width: $md6+px) {
                margin-bottom: 12px;
            }
        }
        &Text {

        }
    }
    .swiperWrap {
        display: none;
        @media(max-width: $md4+px) {
            margin-top: 40px;
            display: block;
        }
    }
    :global(.homeWhyPlatformSwiper) {
        height: 100%;
        overflow: visible;
        padding-bottom: 40px;
    }
    :global(.homeWhyPlatformSwiperSlide) {
        height: auto!important;
    }
    :global(.swiper-pagination) {
        bottom: 0;
    }
    :global(.swiper-pagination-bullet) {
        background: #C5D7D8;
        opacity: 1;
        transition: all .3s;
    }
    :global(.swiper-pagination-bullet-active) {
        background: #325F62;
    }
}