@import "./_var.scss";

body,
html {
    background: #fff;
    color: rgba(0, 0, 0, 0.7);
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: #e3eff2;
    }
    ::-webkit-scrollbar-thumb {
        background: #669295;
    }
    &.lock {
        overflow: hidden;
    }
}
.font-64 {
    font-family: $manrope;
    font-weight: 500;
    font-size: 64px;
    line-height: 140%;
    color: #335f62;
    @media (max-width: $md4+px) {
        font-size: 48px;
    }
    @media (max-width: $md6+px) {
        font-size: 36px;
    }
}
.font-48 {
    font-family: $manrope;
    font-weight: 500;
    font-size: 48px;
    line-height: 140%;
    color: #335f62;
    @media (max-width: $md4+px) {
        font-size: 36px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-40 {
    font-family: $manrope;
    font-weight: 500;
    font-size: 40px;
    line-height: 140%;
    color: #335f62;
    @media (max-width: $md4+px) {
        font-size: 36px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-36 {
    font-family: $manrope;
    font-weight: 500;
    font-size: 36px;
    line-height: 140%;
    @media (max-width: $md4+px) {
        font-size: 24px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-32 {
    font-family: $manrope;
    font-weight: 500;
    font-size: 32px;
    line-height: 140%;
    color: #335f62;
    @media (max-width: $md4+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-24 {
    font-size: 24px;
    font-family: $manrope;
    font-weight: 500;
    line-height: 150%;
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-20-md4 {
    font-family: $manrope;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #335f62;
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
}

.font-20-md6 {
    font-family: $manrope;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #335f62;
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-16 {
    font-family: $roboto;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.text-flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: $md4+px) {
        gap: 18px;
    }
    @media (max-width: $md6+px) {
        gap: 12px;
    }
}

.font-16-manrope {
    font-family: $manrope;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.Dropdown-control {
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    background-color: transparent;
    border: none;
    color: #000;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.Dropdown-arrow {
    border-color: #000 transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #000;
}

.mt {
    margin-top: 160px;
    @media (max-width: $md4+px) {
        margin-top: 140px;
    }
    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
}

.pt {
    padding-top: 160px;
    @media (max-width: $md4+px) {
        padding-top: 140px;
    }
    @media (max-width: $md6+px) {
        padding-top: 120px;
    }
}
.pb {
    padding-bottom: 160px;
    @media (max-width: $md4+px) {
        padding-bottom: 140px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 120px;
    }
}
.mb {
    margin-bottom: 160px;
    @media (max-width: $md4+px) {
        margin-bottom: 140px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bg-dark {
    background: #335f62;
}

#dot {
    width: 6px;
    height: 6px;
    position: fixed;
    background-color: #669295;
    border-radius: 50%;
    margin: -3px 0 0 -3px;
    z-index: 999999;
    pointer-events: none;
    transition: transform 0.3s;
    @media(max-width: $md3+px) {
        display: none;
    }
}

.cursorDotActive {
    transform: scale(2);
}

#cursor {
    width: 40px;
    height: 40px;
    background-color: rgb(0, 0, 0, 0);
    border: 1px solid #669295;
    border-radius: 50%;
    position: fixed;
    margin: -20px 0 0 -20px;
    z-index: 9999;
    pointer-events: none;
    transition:
        transform 0.3s,
        opacity 0.3s;
    @media(max-width: $md3+px) {
        display: none;
    }
}

.cursorCursorActive {
    opacity: 0 !important;
    transform: scale(0);
}
