@import '../../../styles/var';

.startYour {
    position: relative;
    text-align: center;
    .title {
        max-width: 539px;
        margin: 0 auto;
    }
    .steps {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin: 60px 0;
        @media(max-width: $md4+px) {
            gap: 40px;
        }
        @media(max-width: 800px) {
            grid-template-columns: repeat(1, 1fr);
            
        }
        @media(max-width: $md6+px) {
            margin: 40px 0;
        }
        .step {
            position: relative;
            &Image { 
                width: 140px;
                @media(max-width: $md4+px) {
                    width: 120px;
                }
                @media(max-width: $md6+px) {
                    width: 100px;
                }
            }
            &Title {
                margin: 20px 0 16px 0;
            }
            &Text {
                max-width: 215px;
                margin: 0 auto;
            }
        }
    }
    .btn {

    }
}