@import "../../../styles/var";

.more {
}
.moreBody {
}
.moreRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.moreRowLeft {
    flex: 0 1 552px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        order: 2;
        flex: 0 1 100%;
        text-align: center;
        margin-top: 60px;
        img {
            max-width: 552px;
        }
    }
    @media (max-width: $md6+px) {
        margin-top: 40px;
    }
    img {
        width: 100%;
    }
}
.moreRowRight {
    flex: 0 1 666px;
    @media (max-width: $md2+px) {
        flex: 0 1 566px;
    }
}
.moreRowRightTitle {
    margin-bottom: 40px;
    max-width: 500px;
    @media (max-width: $md6+px) {
        max-width: 360px;
        margin-bottom: 20px;
    }
}
.moreRowRightText {
    margin-bottom: 40px;
}
.moreRowRightBtn {
}
