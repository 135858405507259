@import '../../../styles/var';

.ongoing {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        width: 552px;
        margin-left: auto;
        @media(max-width: $md4+px) {
            width: 100%;
            margin-left: 0;
        }
        .title {
            max-width: 511px;
        }
        .text {
            max-width: 700px;
            &_01 {
                margin: 40px 0;
                @media(max-width: $md4+px) {
                    margin-top: 20px;
                }
                @media(max-width: $md6+px) {
                    margin-bottom: 20px;
                }
            }
            &_02 {
                margin: 40px 0;
                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 24px;
            font-weight: 400;
            @media(max-width: $md6+px) {
                gap: 16px;
            }
            li {
                position: relative;
                padding-left: 53px;
                @media(max-width: $md6+px) {
                    padding-left: 37px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: -3px;
                    left: 0;
                    width: 34px;
                    height: 29px;
                    background-image: url('../../../assets/img/SecurityPage/check.png');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    @media(max-width: $md6+px) {
                        width: 27px;
                        height: 23px;
                        top: -2px;
                    }
                }
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 552px;
        @media(max-width: $md2+px) {
            width: 450px;
        }
        @media(max-width: $md3+px) {
            width: 400px;
            left: -50px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 60px auto 0 auto;
            left: unset;
            bottom: unset;
            width: 100%;
            max-width: 468px;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}