@import '../../../styles/var';

.accountTypes {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        width: 620px;
        margin-left: auto;
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .title {

        }
        .text {
            max-width: 552px;
            @media(max-width: $md4+px) {
                max-width: 700px;
            }
            &_01 {
                margin: 40px 0;
                @media(max-width: $md4+px) {
                    margin-top: 20px;
                }
            }
            &_02 {
                margin: 40px 0 40px 0;
                @media(max-width: $md4+px) {
                    margin-top: 40px;
                }
            }
        }
        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 87px);
            gap: 20px;
            @media(max-width: $md4+px) {
                gap: 10px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(1, 70px);
            }
            @media(max-width: $md5+px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 70px);
            }
            @media(max-width: $md6+px) {
                grid-template-rows: repeat(2, 54px);
            }
            .card {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                border-radius: 10px;
                background: #E3EFF2;
                padding: 0 10px;
                span {
                    text-align: center;
                    width: 100%;
                }
            }
        }
        .btn {

        }
    }
    .image {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 80px;
        width: 512px;
        @media(max-width: $md2+px) {
            width: 420px;
            top: calc(50% - 210px);
        }
        @media(max-width: $md3+px) {
            width: 280px;
            top: calc(50% - 140px);
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            top: unset;
            left: unset;
            margin: 30px auto 0 auto;
            width: 440px;
        }
        @media(max-width: $md6+px) {
            width: 335px;
        }
    }
}