@import "../../../styles/var";

.cards {
    background-color: #e3eff2;
    padding: 100px 0;
    position: relative;
    @media (max-width: $md2+px) {
        padding: 70px 0;
    }
    @media (max-width: $md3+px) {
        padding: 70px 0 350px 0;
    }
    @media (max-width: $md4+px) {
        padding: 40px 0 350px 0;
    }
    @media (max-width: 600px) {
        padding: 40px 0 230px 0;
    }
}
.cardsBody {
}
.cardsRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: 600px) {
        max-width: 400px;
    }
}
.cardsRowColumnn {
    text-align: center;
    flex: 0 1 30%;
    &:nth-child(1) {
        margin-bottom: 60px;
        @media (max-width: $md2+px) {
            margin-bottom: 30px;
        }
    }
    @media (max-width: 600px) {
        flex: 0 1 50%;
        &:nth-child(3),
        &:nth-child(1) {
            margin-bottom: 60px;
        }
    }
}
.cardsRowColumnnTitle {
}
.cardsRowColumnnText {
}

.cardsImg1 {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: auto;
    @media (max-width: $md2+px) {
        img {
            max-width: 300px;
        }
    }
    @media (max-width: 600px) {
        img {
            max-width: 200px;
        }
    }
}
.cardsImg2 {
    position: absolute;
    right: 0;

    bottom: 0;
    margin: auto;
    @media (max-width: $md2+px) {
        img {
            max-width: 300px;
        }
    }
    @media (max-width: 600px) {
        img {
            max-width: 200px;
        }
    }
}
