@import '../../../styles/var';

.bannerWrap {
    position: relative;
    background: #E3EFF2;
    overflow: hidden;
}

.banner {
    position: relative;
    height: 583px;
    display: flex;
    align-items: center;
    @media(max-width: $md3+px) {
        height: auto;
        padding-top: 160px;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px;
    }
    @media(max-width: $md6+px) {
        padding-top: 110px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        .text {
            max-width: 522px;
            margin: 40px 0;
            @media(max-width: $md3+px) {
                max-width: 700px;
            }
            @media(max-width: $md4+px) {
                margin: 20px 0 40px 0;
            }
        }
        .btn {

        }
    }
}

.images {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 100%;
    width: 716px;
    @media(max-width: $md2+px) {
        left: 0;
        right: -850px;
        margin: 0 auto;
    }
    @media(max-width: $md3+px) {
        display: none;
    }
    .image {
        position: relative;
        z-index: 1;
        width: 100%;
        
        border-radius: 40%;
        overflow: hidden;
        transform: translate(-50px, 0);
        img {
            width: 100%;
        }
    }
    .circle {
        position: absolute;
        z-index: 2;
        width: 512px;
        top: 0;
        left: -140px;
        transform: translate(0, -200px);
    }
    .sphere {
        position: absolute;
        z-index: 2;
        bottom: 43px;
        left: -19px;
        width: 109px;
        transform: translate(0, 100px);
    }
}

.mob {
    position: relative;
    display: none;
    @media(max-width: $md3+px) {
        display: block;
        width: 100%;
        margin-top: 90px;
    }
    @media(max-width: $md6+px) {
        margin-top: 50px;
    }
}