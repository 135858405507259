@import "../../../styles/var";

.bottom {
}
.bottomBody {
}
.bottomRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.bottomRowLeft {
    flex: 0 1 572px;
    img {
        width: 100%;
    }
    @media (max-width: $md3+px) {
        order: 2;
        margin-top: 60px;
        text-align: center;
        img {
            max-width: 572px;
            margin: 0 auto;
        }
    }
}
.bottomRowRight {
    flex: 0 1 666px;
    @media (max-width: $md2+px) {
        flex: 0 1 566px;
    }
    @media (max-width: $md3+px) {
        order: 1;
        flex: 0 1 666px;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
    }
}
.bottomRowRightText {
    margin-bottom: 40px;
}
.bottomRowRightTable {
    margin-bottom: 50px;
}
.bottomRowRightTableHead {
    display: flex;
    justify-content: space-between;
    padding: 17px 40px 12px 40px;
    border-radius: 10px;
    background: #e3eff2;
    margin-bottom: 12px;
    @media (max-width: 650px) {
        padding: 17px 10px 12px 10px;
    }
}
.bottomRowRightTableHeadItem {
    font-weight: 400 !important;
    &:nth-child(2) {
        flex: 0 1 260px;
    }
    @media (max-width: 650px) {
        &:nth-child(1) {
            flex: 0 0 52% !important;
        }
        &:nth-child(2) {
            flex: 0 0 47% !important;
        }
    }
}
.bottomRowRightTableRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0px 40px 0px 40px;
    @media (max-width: 650px) {
        padding: 0px 10px 0px 10px;
    }
}
.bottomRowRightTableRowItem {
    &:nth-child(2) {
        flex: 0 1 260px;
    }
    @media (max-width: 650px) {
        &:nth-child(1) {
            flex: 0 0 52% !important;
        }
        &:nth-child(2) {
            flex: 0 0 47% !important;
        }
    }
}
.bottomRowRightBtn {
}
