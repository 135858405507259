@import "../../../styles/var";

.requirements {
}
.requirementsBody {
}
.requirementsRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.requirementsRowLeft {
    flex: 0 1 666px;
    @media (max-width: $md2+px) {
        flex: 0 1 586px;
    }
    @media (max-width: 700px) {
        flex: 0 1 100%;
    }
}
.requirementsRowLeftTitle {
    margin-bottom: 20px;
}
.requirementsRowLeftText {
    max-width: 552px;
    margin-bottom: 40px;
    @media (max-width: $md2+px) {
        margin-bottom: 30px;
    }
}
.requirementsRowLeftRow {
    margin-bottom: 70px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: $md2+px) {
        margin-bottom: 55px;
    }
}
.requirementsRowLeftRowColumn {
    flex: 0 1 48%;
    text-align: center;
    border-radius: 10px;
    background: #e3eff2;
    padding: 28px 30px;
    @media (max-width: $md2+px) {
        padding: 15px;
    }
    @media (max-width: 700px) {
        flex: 0 1 100%;
    }
}
.requirementsRowLeftBtn {
}
.requirementsRowRight {
    flex: 0 1 564px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        text-align: center;
        img {
            max-width: 564px;
        }
        margin-top: 40px;
    }
    img {
        width: 100%;
    }
}
