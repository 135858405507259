@import "../../../styles/var";

.banner {
    position: relative;
}
.bannerBody {
    padding: 130px 0 100px 0;
    background-color: #e3eff2;
    @media (max-width: $md3+px) {
        padding: 100px 0 0 0;
    }
}
.bannerWrapper {
    max-width: 585px;
    position: relative;
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.bannerTitle {
    margin-bottom: 40px;
    position: relative;
    z-index: 5;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
    @media (max-width: $md3+px) {
        max-width: 585px;
    }
}
.bannerText {
    margin-bottom: 40px;
    position: relative;
    z-index: 5;
}
.bannerBtn {
    position: relative;
    z-index: 5;
}
.bannerImg {
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: $md2+px) {
        right: -150px;
    }
    height: 100%;
    border-radius: 40%;
    overflow: hidden;
    transform: translate(-50px, 0);
    img {
        height: 100%;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerCircle {
    width: 109px;
    height: 109px;
    background-color: #effcff;
    border-radius: 50%;
    bottom: 40px;
    right: 630px;
    z-index: 3;
    position: absolute;
    transform: translate(0, 100px);
    @media (max-width: $md2+px) {
        right: 480px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerCircleBig {
    width: 512px;
    height: 512px;
    border-radius: 50%;
    border: 76px solid #effcff;
    position: absolute;
    top: -320px;
    right: 410px;
    z-index: 3;
    transform: translate(0, -200px);
    @media (max-width: $md2+px) {
        right: 260px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}

.bannerMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
    }
    margin-top: 80px;
    @media (max-width: $md5+px) {
        margin-top: 60px;
    }
    @media (max-width: $md6+px) {
        margin-top: 50px;
    }
    text-align: right;
    img {
        max-width: 716px;
    }
    @media (max-width: $md5+px) {
        display: none;
    }
}
.bannerMob1 {
    display: none;
    @media (max-width: $md5+px) {
        display: block;
    }
    margin-top: 40px;
    text-align: right;
}
