@import "../../../styles/var";

.most {
}
.mostBody {
}
.mostRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.mostRowLeft {
    flex: 0 1 552px;
    @media (max-width: $md3+px) {
        text-align: center;
    }
}
.mostRowLeftTitle {
    margin-bottom: 40px;
}
.mostRowLeftTable {
    max-width: 385px;
    @media (max-width: $md3+px) {
        margin: 0 auto;
    }
}
.mostRowLeftTableRow {
    display: flex;
    justify-content: space-between;
}
.mostRowLeftTableRowItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
        width: 34px;
        height: 20px;
        object-fit: contain;
        object-position: center center;
    }
    &:nth-child(1) {
        img {
            margin-right: 15px;
        }
    }
    &:nth-child(2) {
        flex: 0 1 140px;
        img {
            margin-right: 10px;
        }
    }
}
.mostRowRight {
    flex: 0 1 666px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        text-align: center;
        margin-top: 60px;
        img {
            max-width: 666px;
        }
    }
    img {
        width: 100%;
    }
}
