@import '../../../styles/var';

.wideBonus {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    background: #E3EFF2;
    padding: 60px 250px 90px 60px;
    @media(max-width: $md2+px) {
        padding: 60px 200px 80px 60px;
    }
    @media(max-width: $md3+px) {
        padding-right: 60px;
    }
    @media(max-width: $md4+px) {
        padding: 60px 76px 510px 76px;
    }
    @media(max-width: 700px) {
        padding: 60px 40px 400px 40px;
    }
    @media(max-width: $md6+px) {
        padding: 60px 20px 350px 20px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {
            max-width: 480px;
            @media(max-width: $md4+px) {
                text-align: center;
                margin: 0 auto;
            }
        }
        .cards {
            margin: 40px 0 60px 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(1, 87px);
            gap: 20px;
            @media(max-width: $md4+px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, 87px);
            }
            @media(max-width: $md5+px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(4, 80px);
                gap: 12px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 40px;
                grid-template-rows: repeat(4, 64px);
            }
            .card {
                display: flex;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background: #C4DADC;
                padding: 0 10px;
                span {
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .text {
            max-width: 552px;
            @media(max-width: $md4+px) {
                text-align: center;
                margin: 0 auto;
            }
        }
        .btn {
            margin-top: 20px;
            @media(max-width: $md4+px) {
                margin-top: 40px;
                text-align: center;
            }
        }
    }
    .line {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 1147px;
        @media(max-width: $md2+px) {
            width: 900px;
        }
        @media(max-width: $md3+px) {
            width: 800px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
    }
    .money {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        width: 814px;
        @media(max-width: $md2+px) {
            width: 600px;
        }
        @media(max-width: $md3+px) {
            width: 500px;
        }
        @media(max-width: $md4+px) {
            width: 700px;
        }
        @media(max-width: 700px) {
            width: 600px;
        }
        @media(max-width: $md6+px) {
            width: 450px;
        }
    }
    .pig {
        position: absolute;
        z-index: 3;
        bottom: 11px;
        right: 62px;
        width: 277px;
        @media(max-width: $md2+px) {
            width: 240px;
        }
        @media(max-width: $md4+px) {
            width: 277px;
            bottom: 30px;
            right: 10px;
        }
        @media(max-width: 700px) {
            width: 240px;
        }
        @media(max-width: $md6+px) {
            width: 220px;
            right: 0;
        }
    }
}