@import '../../../styles/var';

.bannerWrap {
    position: relative;
    overflow: hidden;
    background: #E3EFF2;
    max-width: 2200px;
    margin: 0 auto;
}

.banner {
    position: relative;
    z-index: 10;
    padding-top: 140px;
    height: 100vh;
    min-height: 800px;
    max-height: 1000px;
    @media(max-width: $md2+px) {
        min-height: 750px;
    }
    @media(max-width: $md3+px) {
        height: auto;
        min-height: unset;
        max-height: unset;
        padding-bottom: 200px;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px;
        padding-bottom: 0;
    }
    @media(max-width: $md6+px) {
        padding-top: 110px;
    }
    .content {
        position: relative;
        z-index: 10;
        opacity: 0;
        transform: translateX(-120px) translateY(40px) scale(0.85);
        @media(max-width: $md3+px) {
            opacity: 1;
            transform: none;
        }
        .title {

        }
        .subtitle {
            max-width: 686px;
            @media(max-width: $md4+px) {
                max-width: 546px;
            }
        }
        .text {
            margin: 40px 0;
            max-width: 552px;
            @media(max-width: $md4+px) {
                margin-top: 20px;
                max-width: 700px;
            }
        }
        .btn {

        }
    }
}

.images {
    position: absolute;
    z-index: 2;
    width: 722px;
    right: 144px;
    bottom: 0;
    opacity: 0;
    @media(max-width: $md3+px) {
        opacity: 1;
    }
    @media(max-width: $md2+px) {
        transform: scale(0.8);
        right: 42px;
        bottom: -75px;
    }
    @media(max-width: $md3+px) {
        transform: scale(0.6);
        bottom: -150px;
        right: -60px;
    }
    @media(max-width: $md4+px) {
        right: unset;
        bottom: unset;
        transform: none;
        display: flex;
        position: relative;
        width: 549px;
        margin: 0 auto;
    }
    @media(max-width: 600px) {
        width: 348px;
        margin-top: 40px;
    }
    .man {
        width: 100%;
        position: relative;
        z-index: 5;
    }
    .items_01 {
        position: absolute;
        z-index: 2;
        left: -155px;
        bottom: 0;
        width: 510px;
        @media(max-width: $md4+px) {
            width: 389px;
            left: -119px;
        }
        @media(max-width: 600px) {
            width: 246px;
            left: -75px;
        }
    }
    .items_02 {
        position: absolute;
        z-index: 2;
        width: 420px;
        bottom: -70px;
        right: -145px;
        @media(max-width: $md4+px) {
            bottom: -53px;
            width: 340px;
            right: -220px;
        }
        @media(max-width: 600px) {
            width: 200px;
            bottom: -33px;
            right: -150px;
        }
    }
}

.line {
    position: absolute;
    z-index: 1;
    width: 829px;
    left: 0;
    bottom: 0;
    @media(max-width: $md2+px) {
        width: 700px;
    }
    @media(max-width: $md3+px) {
        width: 500px;
    }
}