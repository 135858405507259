@import '../../../styles/var';

.protection {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        .title {
            max-width: 511px;
        }
        .text {
            margin: 50px 0 40px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            max-width: 552px;
            @media(max-width: $md4+px) {
                margin-top: 20px;
                max-width: 700px;
            }
            @media(max-width: $md6+px) {
                gap: 12px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        bottom: 70px;
        right: 0;
        width: 666px;
        @media(max-width: $md2+px) {
            width: 560px;
        }
        @media(max-width: $md3+px) {
            width: 500px;
            right: -120px;
        }
        @media(max-width: $md4+px) {
            display: flex;
            position: relative;
            bottom: unset;
            right: unset;
            margin: 60px auto 0 auto;
            width: 100%;
            max-width: 633px;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}