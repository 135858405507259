@import "../../../styles/var";

.join {
}
.joinBody {
}
.joinRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.joinRowLeft {
}
.joinRowLeftTitle {
    margin-bottom: 40px;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
}
.joinRowLeftText {
    max-width: 552px;
    margin-bottom: 40px;
}
.joinRowLeftBtn {
}
.joinRowRight {
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-top: 50px;
        text-align: center;
    }
    @media (max-width: $md6+px) {
        margin-top: 40px;
    }
    img {
        max-width: 550px;
        @media (max-width: $md5+px) {
            max-width: 100%;
        }
    }
}
