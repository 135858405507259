@import "../../../styles/var";

.types {
}
.typesBody {
}
.typesTitle {
    max-width: 450px;
    text-align: center;
    margin: 0 auto 60px auto;
}
.typesRow {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: $md3+px) {
        display: none;
    }
}
.typesRowColumn {
    flex: 0 1 656px;
    border-radius: 10px;
    background: #e3eff2;
    box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
    padding: 50px 40px 40px 20px;
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md2+px) {
        padding: 50px 20px 40px 20px;
    }
}
.typesRowColumnItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.typesRowColumnItemImg {
    img {
        width: 200px;
        @media (max-width: $md2+px) {
            width: 170px;
        }
    }
}
.typesRowColumnItemContent {
    flex: 0 1 370px;
    @media (max-width: $md2+px) {
        flex: 0 1 320px;
    }
}
.typesRowColumnItemContentTitle {
    margin-bottom: 20px;
}
.typesRowColumnItemContentText {
}

.cards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-top: 40px;
    @media (max-width: $md4+px) {
        display: none;
    }
}
.card {
    position: relative;
    padding: 40px 40px 48px 40px;
    border-radius: 10px;
    background: #e3eff2;
    box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
    width: 100%;
    height: 100%;
    @media (max-width: $md3+px) {
        padding: 30px 30px 260px 30px;
    }

    @media (max-width: $md6+px) {
        padding: 20px 20px 240px 20px;
    }
    &Icon {
        width: 160px;
        margin: 0 auto;
        text-align: center;
    }
    &Title {
        margin-bottom: 16px;
        @media (max-width: $md6+px) {
            margin-bottom: 12px;
        }
    }
    &Text {
    }
}
.swiperWrap {
    @media (max-width: $md4+px) {
        margin-top: 40px;
    }
}
:global(.homeWhyPlatformSwiper) {
    height: 100%;
    overflow: visible;
    padding-bottom: 40px;
}
:global(.homeWhyPlatformSwiperSlide) {
    height: auto !important;
}
:global(.swiper-pagination) {
    bottom: 0;
}
:global(.swiper-pagination-bullet) {
    background: #c5d7d8;
    opacity: 1;
    transition: all 0.3s;
}
:global(.swiper-pagination-bullet-active) {
    background: #325f62;
}

.typesMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
    }
}

.typesBtn {
    margin-top: 40px;
    text-align: center;
}

.cardIconWrap {
    text-align: center;
    margin-top: 20px;
    position: absolute;
    bottom: 20px;
    width: 100%;

    left: 0;

    margin: auto;
    img {
        height: auto !important;
    }
}
