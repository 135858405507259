@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #e3eff2;
    transition: all 0.1s;
    &.hideBg {
        background-color: rgba($color: #fff, $alpha: 0);
    }
    &.lightBg {
        .headerRowLinks {
            a {
                color: #000;
            }
            .linkActive {
                color: #3279ff;
            }
        }
        :global(.Dropdown-control) {
            color: #000 !important;
        }
        :global(.Dropdown-arrow) {
            border-color: #000 transparent transparent !important;
        }
        :global(.is-open .Dropdown-arrow) {
            border-color: transparent transparent #000 !important;
        }
    }
    @media (max-width: $md3+px) {
        height: 67px;
    }
    @media (max-width: $md6+px) {
        height: 61px;
    }
    &::before {
        content: "";
        background: #e3eff2;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        z-index: -1;
    }
    &Active {
        height: 100%;
        &::before {
            opacity: 1;
            height: 100%;
        }
        .langs {
            display: none;
        }
        path {
            fill: white;
        }
    }
    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;
        .aLink {
            position: relative;
            z-index: 10;
            display: block;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
            font-family: $roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
            &.linkActive {
                color: #669295;
            }
        }
        .btn {
            position: relative;
            z-index: 10;
            justify-content: center;
            display: flex;
            margin-top: 40px;
            a {
                @media (min-width: 1221px) {
                    display: none;
                }
                display: block;
                padding: 10px 20px;
                border: 1px solid #669295;
                border-radius: 40px;
                font-family: $roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                cursor: pointer;
                transition: all 0.3s;
                color: #fff;
                background: #669295;
                &::before {
                    display: none;
                }
                &:hover {
                    color: #669295;
                    background: transparent;
                }
            }
        }
        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }
    .headerRow {
        display: grid;
        grid-template-columns: 150px 1fr auto;
        gap: 60px;
        align-items: center;
        padding: 12px 0;
        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }
        &Logo {
            @media (max-width: $md3+px) {
                width: 140px;
            }
            @media(max-width: $md6+px) {
                width: 120px;
            }
            a {
                width: 100%;
                height: 100%;
                display: block;
            }
            img {
                width: 100%;
                height: auto;
                @media (max-width: $md1+px) {
                }
                @media (max-width: $md3+px) {
                }
            }
            svg {
                width: 100%;
                path {
                    transition: all 0.3s;
                }
                @media (max-width: $md3+px) {
                    width: 46px;
                }
            }
        }
        &Links {
            @media (max-width: $md3+px) {
                display: none;
            }
            display: flex;
            gap: 40px;
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            .linkActive {
                color: #669295;
            }
            a {
                position: relative;
                color: #000;
                text-decoration: none;
            }
        }
        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;
            .login {
                @media (max-width: $md3+px) {
                    display: none;
                }
                padding: 10px 20px;
                background: #669295;
                border: 1px solid #669295;
                border-radius: 40px;
                font-family: $roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #fff;
                cursor: pointer;
                transition: all 0.3s;
                &:hover {
                    color: #669295;
                    background: transparent;
                }
            }
            .burger {
                @media (min-width: 1221px) {
                    display: none;
                }
                cursor: pointer;
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #669295;
                    border-radius: 10px;
                    transition: all 0.3s;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #669295;
                    border-radius: 10px;
                    transition: all 0.3s;
                }
                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                        // background: #FFF;
                    }
                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                        // background: #FFF;
                    }
                }
            }
        }
    }
}
