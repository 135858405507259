@import "../../../styles/var";

.create {
}
.createBody {
}
.createRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.createRowLeft {
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.createRowLeftTitle {
    @media (max-width: $md2+px) {
        max-width: 400px;
    }
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
    @media (max-width: 700px) {
        max-width: 400px;
    }
    @media (max-width: $md6+px) {
        max-width: 320px;
    }
}
.createRowLeftText {
    max-width: 552px;
    margin: 40px 0;
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.createRowLeftBtn {
}
.createRowRight {
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        text-align: center;
        margin-top: 60px;
        img {
            max-width: 616px;
            width: 100%;
        }
    }
}
