@import '../../../styles/var';

.transparencyWrap {
    position: relative;
    background: #E3EFF2;
    width: 100%!important;
}

.transparency {
    position: relative;
    padding: 60px 0 80px 0;
    @media(max-width: $md3+px) {
        padding: 60px 0 70px 0;
    }
    @media(max-width: $md6+px) {
        padding-bottom: 50px;
    }
    .title {
        position: relative;
        text-align: center;
    }
    .text {
        position: relative;
        text-align: center;
        max-width: 522px;
        margin: 40px auto 40px auto;
        @media(max-width: $md4+px) {
            margin-top: 20px;
        }
    }
    .cards {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
        gap: 20px;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .card {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
        padding: 40px 40px 60px 40px;
        @media(max-width: $md3+px) {
            width: 100%;
            height: 100%;
        }
        @media(max-width: $md6+px) {
            padding: 20px 20px 40px 20px;
        }
        &Icon {
            width: 40px;
        }
        &Title {
            color: #335F62;
            margin: 20px 0 16px 0;
            @media(max-width: $md6+px) {
                margin-bottom: 12px;
            }
        }
        &Text {

        }
    }
    .swiperWrap {
        display: none;
        @media(max-width: $md3+px) {
            display: block;
        }
    }
    :global(.securityTransSwiper) {
        height: 100%;
        overflow: visible;
        padding-bottom: 30px;
    }
    :global(.securityTransSwiperSlide) {
        height: auto!important;
    }
    :global(.swiper-pagination) {
        bottom: 0;
    }
    :global(.swiper-pagination-bullet) {
        background: #C5D6D8;
        opacity: 1;
        transition: all .3s;
    }
    :global(.swiper-pagination-bullet-active) {
        background: #325F62;
    }
}