@import '../../../styles/var';

.ourComm {
    position: relative;
    text-align: center;
    .title {

    }
    .text {
        margin: 20px auto 60px auto;
        max-width: 495px;
        @media(max-width: $md6+px) {
            margin-bottom: 40px;
        }
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .card {
            position: relative;
            &Image {
                width: 140px;
                @media(max-width: $md4+px) {
                    width: 120px;
                }
                @media(max-width: $md6+px) {
                    width: 100px;
                }
            }
            &Text {
                margin: 30px auto 0 auto;
                max-width: 311px;
                @media(max-width: $md6+px) {
                    margin-top: 20px;
                }
            }
        }
    }
    .btn {
        margin-top: 40px;
    }
}